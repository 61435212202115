import React from "react";
import { Link } from "gatsby";

import happeningsBG from "../images/happeningsBGImage.png";
import nextEventIcon from "../images/nextEventIcon.png";
import clockIcon from "../images/clockIcon.png";
import markerIcon from "../images/markerIcon.png";

import happeningsStyles from "./events.module.css";
import Head from "../components/head";
import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulEvent(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          date(formatString: "MMMM DD, YYYY")
          time
          locationAddress
          slug
        }
      }
    }
    allContentfulHomePage {
      edges {
        node {
          noEventsText
        }
      }
    }
  }
`;

class happeningsPage extends React.Component {
  state = {
    eventDates: [],
  };
  componentDidMount() {
    let tempArr = [];

    for (let i = 0; i < this.props.data.allContentfulEvent.edges.length; i++) {
      let todaysDate = new Date();
      let dateString = todaysDate.getTime();
      let newTime =
        this.props.data.allContentfulEvent.edges[i].node.date +
        " " +
        this.props.data.allContentfulEvent.edges[i].node.time;
      let eventDate = new Date(newTime);
      console.log(eventDate);
      let eventDateString = eventDate.getTime();

      if (
        eventDateString > dateString &&
        this.props.data.allContentfulEvent.edges[i].node.title !== "null"
      ) {
        tempArr.push(this.props.data.allContentfulEvent.edges[i]);
      }
    }

    //add to state.
    this.setState({ eventDates: tempArr });
  }

  render() {
    return (
      <>
      <Head title="TSS events"></Head>
        {this.state.eventDates.length === 0 ? (
          <div
            className={happeningsStyles.happeningsHeroSection}
            style={{ flexDirection: "column" }}
          >
            <img src={happeningsBG} />
            <h2 style={{ textAlign: "center" }}>
              {this.props.data.allContentfulHomePage.edges[0].node.noEventsText}
            </h2>
          </div>
        ) : (
          <>
            <div className={happeningsStyles.happeningsHeroSection}>
              <img src={happeningsBG} />
              <div className={happeningsStyles.nextEventContainer}>
                <Link to={`/event/${this.state.eventDates[0].node.slug}`}>
                  <img src={nextEventIcon}></img>
                  <div>
                    <p className={happeningsStyles.nextEventTop}>Next Event</p>
                    <p className={happeningsStyles.nextEventBottom}>
                      {this.state.eventDates[0].node.title}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <section className={happeningsStyles.eventSection}>
              <div>
                <h2>Upcoming Events</h2>

                {this.state.eventDates.map((edge, index) => {
                  if (edge.node.title !== "null") {
                    return (
                      <div key={index} className={happeningsStyles.eventItem}>
                        <Link to={`/event/${edge.node.slug}`}>
                          <p className={happeningsStyles.date}>
                            {edge.node.date}
                          </p>
                          <h3 className={happeningsStyles.name}>
                            {edge.node.title}
                          </h3>
                          <div>
                            <img src={markerIcon}></img>{" "}
                            <span>{edge.node.locationAddress}</span>
                          </div>
                          <div>
                            <img src={clockIcon}></img>{" "}
                            <span>{edge.node.time}</span>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                })}
              </div>
            </section>
          </>
        )}
      </>
    );
  }
}
export default happeningsPage;
